declare var $: any;
const months = ['January','February','March','April', 'May','June','July','August', 
'September','October','November','December'];
const dayOfWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function compareTime(time1:string, time2:string){
    let str1 = time1.split('/');
    let str2 = time2.split('/');
    let i = 2;
    for(; i >= 0; i--){
        if (parseInt(str1[i]) == parseInt(str2[i])){
            continue;
        }
        if (parseInt(str2[i]) > parseInt(str1[i])){
            return 1;
        }
        else{
            return -1;
        }
    }
    return 0;
}

export const _dateTime = function(){
    // initFormBooking();
    dateTimePickerOrder();
}

function dateTimePickerOrder(){
    if (document.getElementById('receiveOrder')){
        $('#receiveOrder').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
          
            timepicker:false,
            format: 'd/m/Y',
            minDate: new Date(),
    
            onChangeDateTime:function(dateChange:any){
                let outDate = <HTMLInputElement>document.getElementById('payOrder');
                let newOutDate = outDate.value;
    
                let inDate = new Date(dateChange);
                const yyyy = inDate.getFullYear();
                let mm = inDate.getMonth() + 1; // Months start at 0!
                let dd = inDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }
    
                // let newInDate = `${inDate.getDate()}/${inDate.getMonth() + 1}/${inDate.getFullYear()}`;
                let newInDate = `${date}/${month}/${yyyy}`;
                if (newOutDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        outDate.value = newInDate;
                    }
                }
                else{
                    outDate.value = newInDate;
                }
            }
        });
    }
    if (document.getElementById('payOrder')){
        $('#payOrder').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            timepicker:false,
            format: 'd/m/Y',
            minDate: new Date(), 
            onChangeDateTime:function(dateChange:any){
                // let outDate = new Date(dateChange);
                // let newOutDate = `${outDate.getDate()}/${outDate.getMonth() + 1}/${outDate.getFullYear()}`;
                
                let inDate = <HTMLInputElement>document.getElementById('receiveOrder');
                let newInDate = inDate.value;
                
                let outDate = new Date(dateChange);
                const yyyy = outDate.getFullYear();
                let mm = outDate.getMonth() + 1; // Months start at 0!
                let dd = outDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }
                let newOutDate = `${date}/${month}/${yyyy}`;

                if (newInDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        inDate.value = newOutDate;
                    }
                }
                else{
                    inDate.value = newOutDate;
                }
            }
        });
    }
    if (document.getElementById('receiveFooter')){
        $('#receiveFooter').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
          
            timepicker:false,
            format: 'd/m/Y',
            minDate: new Date(),
    
            onChangeDateTime:function(dateChange:any){
                let outDate = <HTMLInputElement>document.getElementById('payFooter');
                let newOutDate = outDate.value;
    
                let inDate = new Date(dateChange);
                const yyyy = inDate.getFullYear();
                let mm = inDate.getMonth() + 1; // Months start at 0!
                let dd = inDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }
    
                // let newInDate = `${inDate.getDate()}/${inDate.getMonth() + 1}/${inDate.getFullYear()}`;
                let newInDate = `${date}/${month}/${yyyy}`;
                if (newOutDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        outDate.value = newInDate;
                    }
                }
                else{
                    outDate.value = newInDate;
                }
            }
        });
    }
    if (document.getElementById('payFooter')){
        $('#payFooter').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            timepicker:false,
            format: 'd/m/Y',
            minDate: new Date(), 
            onChangeDateTime:function(dateChange:any){
                // let outDate = new Date(dateChange);
                // let newOutDate = `${outDate.getDate()}/${outDate.getMonth() + 1}/${outDate.getFullYear()}`;
                
                let inDate = <HTMLInputElement>document.getElementById('receiveFooter');
                let newInDate = inDate.value;
                
                let outDate = new Date(dateChange);
                const yyyy = outDate.getFullYear();
                let mm = outDate.getMonth() + 1; // Months start at 0!
                let dd = outDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }
                let newOutDate = `${date}/${month}/${yyyy}`;

                if (newInDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        inDate.value = newOutDate;
                    }
                }
                else{
                    inDate.value = newOutDate;
                }
            }
        });
    }
    if (document.getElementById('receiveFormStep')){
        $('#receiveFormStep').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
          
            timepicker:false,
            format: 'd/m/Y',
            minDate: new Date(),
    
            onChangeDateTime:function(dateChange:any){
                let outDate = <HTMLInputElement>document.getElementById('payFormStep');
                let newOutDate = outDate.value;
    
                let inDate = new Date(dateChange);
                const yyyy = inDate.getFullYear();
                let mm = inDate.getMonth() + 1; // Months start at 0!
                let dd = inDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }
    
                // let newInDate = `${inDate.getDate()}/${inDate.getMonth() + 1}/${inDate.getFullYear()}`;
                let newInDate = `${date}/${month}/${yyyy}`;
                if (newOutDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        outDate.value = newInDate;
                    }
                }
                else{
                    outDate.value = newInDate;
                }
            }
      });
    }
    if (document.getElementById('payFormStep')){
      $('#payFormStep').datetimepicker({
        i18n:{
            de:{
             months:[
              'Januar','Februar','März','April',
              'Mai','Juni','Juli','August',
              'September','Oktober','November','Dezember',
             ],
             dayOfWeek:[
              "So.", "Mo", "Di", "Mi", 
              "Do", "Fr", "Sa.",
             ]
            }
        },
        timepicker:false,
        format: 'd/m/Y',
        minDate: new Date(), 
        onChangeDateTime:function(dateChange:any){
            // let outDate = new Date(dateChange);
            // let newOutDate = `${outDate.getDate()}/${outDate.getMonth() + 1}/${outDate.getFullYear()}`;
            
            let inDate = <HTMLInputElement>document.getElementById('receiveFormStep');
            let newInDate = inDate.value;
            
            let outDate = new Date(dateChange);
            const yyyy = outDate.getFullYear();
            let mm = outDate.getMonth() + 1; // Months start at 0!
            let dd = outDate.getDate();
            let date = '';
            let month = '';
            if (dd < 10) {
                date = '0' + dd;
            }
            else{
                date = dd + '';
            }
            if (mm < 10) {
                month = '0' + mm;
            }
            else{
                month = mm + '';
            }
            let newOutDate = `${date}/${month}/${yyyy}`;

            if (newInDate){
                let compare = compareTime(newInDate, newOutDate);
                if (compare == -1){
                    inDate.value = newOutDate;
                }
            }
            else{
                inDate.value = newOutDate;
            }
        }
      });
    }

    if (document.getElementById('dateToolbarStart')){
      $('#dateToolbarStart').datetimepicker({
        i18n:{
            de:{
             months:[
              'Januar','Februar','März','April',
              'Mai','Juni','Juli','August',
              'September','Oktober','November','Dezember',
             ],
             dayOfWeek:[
              "So.", "Mo", "Di", "Mi", 
              "Do", "Fr", "Sa.",
             ]
            }
        },
      
        timepicker:false,
        format: 'd/m/Y',
        minDate: new Date(),

        onChangeDateTime:function(dateChange:any){
            let outDate = <HTMLInputElement>document.getElementById('dateToolbarEnd');
            let newOutDate = outDate.value;

            let inDate = new Date(dateChange);
            const yyyy = inDate.getFullYear();
            let mm = inDate.getMonth() + 1; // Months start at 0!
            let dd = inDate.getDate();
            let date = '';
            let month = '';
            if (dd < 10) {
                date = '0' + dd;
            }
            else{
                date = dd + '';
            }
            if (mm < 10) {
                month = '0' + mm;
            }
            else{
                month = mm + '';
            }

            // let newInDate = `${inDate.getDate()}/${inDate.getMonth() + 1}/${inDate.getFullYear()}`;
            let newInDate = `${date}/${month}/${yyyy}`;
            if (newOutDate){
                let compare = compareTime(newInDate, newOutDate);
                if (compare == -1){
                    outDate.value = newInDate;
                }
            }
            else{
                outDate.value = newInDate;
            }
        }
      });
    }

    if (document.getElementById('dateToolbarEnd')){
      $('#dateToolbarEnd').datetimepicker({
        i18n:{
            de:{
             months:[
              'Januar','Februar','März','April',
              'Mai','Juni','Juli','August',
              'September','Oktober','November','Dezember',
             ],
             dayOfWeek:[
              "So.", "Mo", "Di", "Mi", 
              "Do", "Fr", "Sa.",
             ]
            }
        },
        timepicker:false,
        format: 'd/m/Y',
        minDate: new Date(), 
        onChangeDateTime:function(dateChange:any){
            // let outDate = new Date(dateChange);
            // let newOutDate = `${outDate.getDate()}/${outDate.getMonth() + 1}/${outDate.getFullYear()}`;
            
            let inDate = <HTMLInputElement>document.getElementById('dateToolbarStart');
            let newInDate = inDate.value;
            
            let outDate = new Date(dateChange);
            const yyyy = outDate.getFullYear();
            let mm = outDate.getMonth() + 1; // Months start at 0!
            let dd = outDate.getDate();
            let date = '';
            let month = '';
            if (dd < 10) {
                date = '0' + dd;
            }
            else{
                date = dd + '';
            }
            if (mm < 10) {
                month = '0' + mm;
            }
            else{
                month = mm + '';
            }
            let newOutDate = `${date}/${month}/${yyyy}`;

            if (newInDate){
                let compare = compareTime(newInDate, newOutDate);
                if (compare == -1){
                    inDate.value = newOutDate;
                }
            }
            else{
                inDate.value = newOutDate;
            }
        }
      });
    }

    $.datetimepicker.setLocale('en');
}
